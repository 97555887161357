.hero{
   
    padding-top: 30px;
    padding-bottom: 40px;

    @include desktop{
        padding-top: 75px;
        padding-bottom: 150px;
    }

    // display: flex;
    // align-items: center;
    // justify-content: center;
    // &.bg{
    //     min-height: calc(100vh - 90px);
    //     background-position: top 0 left 0;
    //     background-repeat: no-repeat;
    //     background-size: cover;
    //     background-image: url('../img/1.png');
    //     @include desktop{
    //         background-position: center;
    //     }

    .content-wrapper{
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: space-between;
        gap: 40px;

        @include desktop{
            flex-direction: row;
        }

    }

    p{
        margin-top: 24px;
        margin-bottom: 24px;
    }

    img{
        box-sizing: $box-shadow;
    }
    
}



.about{
    background-color: $accent;
    text-align: center;
}

.mechanic{

}

.gallery{   
    background-color: $accent;
    text-align: center;
    .list{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
        @include desktop{
            flex-direction: row;
            flex-wrap: wrap;
            align-items: stretch;
        }

        &__item{
            
        }
        .image{
            width: 100%;
            max-width: 600px;
            box-shadow: $box-shadow;
            @include desktop{
                max-width: 484px;
            }
        }
    }
}

.contacts{
    padding-top: 100px;
    padding-bottom: 100px;

}

.address{
    padding-top: 40px;
    padding-bottom: 40px;
    text-align: center;

    .subtitle{
        margin-top: 24px;
        padding: 40px 12px;

        text-shadow: 4px 4px 4px 0 rgba(0, 0, 0, 0.25);;

        box-shadow: 4px 4px 4px 0 rgba(0, 0, 0, 0.25);
            background: #fff;
            border-radius: 12px;
    }
}

// =========================== privacy & terms :start

.privacy{
    background-color: $white;

    h2{
        font-weight: 800;
        font-size: 40px;
        text-transform: uppercase;
        text-align: center;
    }
}

// =========================== privacy & terms end 