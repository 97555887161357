.form{
    font-family: "Nunito Sans", sans-serif;;
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 40px;

    width: 100%;
    max-width: 600px;

    background-color: $second-bg;
    border-radius: 4px;
    margin:  0 auto;
    &-label{
        width: 100%;
    }

    &-input{
        font-family: inherit;
        width: 100%;
        padding: 8px 16px;
        background: #fff;
        border: 2px solid transparent;
        font-size: 14px;
        line-height: 1.2;
        font-weight: 400;
        border-radius: 6px;
        color: #293037;
        transition: $transition;
        outline: none;

        &:hover,&:focus{
            border: 2px solid $accent;
        }

        &::placeholder{
            color: #293037;
            opacity: 0.6;
            font-size: 14px;
            line-height: 1.14;
            font-weight: 400;
        }

        &.textarea{
            resize: none;
            min-height: 120px;
            border-radius: 8px;
        }
    
    }

    .app-link{
        font-family: inherit;
        max-width: 100%;
        text-decoration: none;
    }


}