// font family
$main-font: "Inter", sans-serif;
$mont: "Montserrat", sans-serif;
$ffjost: "Jost", sans-serif;
$fflogo: "Patua One", serif;

// $second-font:"Helvetica", sans-serif;

// bg
$main-bg:#AEDCED;
$second-bg: #6C98F2;
// text

$box-shadow: 4px 4px 0 0 rgba(255, 253, 253, 0.4);
$btn-shadow: 4px 4px 0 0 #000;

// transition
$transition: opacity 0.3s ease-in-out, color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, border-bottom 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

$white: #FFFFFF;
$black:#000000;
$accent:#F9C9FD;
$second: #bc00cd;
$error:#ff3636;

// modal
$modal-bgd: #2C2C2C;


