// =========================== privacy & terms start 
.privacy{
    padding-top: 60px;
    padding-bottom: 60px;
    font-size: 16px;
    font-weight: 400;
    color: $black;

    .title{
        font-weight: 800;
        font-size: 40px;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 40px;
        
        @include desktop{
            font-size: 40px;
        }
    }
    h2.title{
        color: $accent;
    }

    & .wrapper{
        max-width: 1040px;
        margin: 0 auto;
    }

    .upper{
        display: flex;
        flex-direction: column;
        gap: 40px;
        max-width: 1000px;
        margin: 0 auto;
        margin-bottom: 40px;
        @include tablet{
            flex-direction: row;
        }
        p{
            max-width: 592px;
        }
    }

    .lower{
        border-radius: 12px;
        padding: 60px 24px;
        max-width: 1000px;
        margin: 0 auto;
        background-color: #fff5c7;

        .mb24{
            margin-bottom: 24px;
        }

        &-title{
            font-weight: 600;
            margin-bottom: 24px;
        }

        &-text{
            margin-top: 24px;
        }
    }

    .font600{
        font-weight: 600;
    }
}


// =========================== privacy & terms end 