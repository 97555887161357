// ========================================= common start
html,
body {
  font-family: $main-font;
  background-color: $main-bg;

  font-weight: 500;
  font-size: 16px;
  line-height: 1.2;
  color: $black;
  
  margin: 0 auto;
  width: 100%;

  scroll-behavior: smooth;
  overflow-x: hidden;

  @include desktop{
    font-size: 22px;
  }
}

section{
  padding-top: 60px;
  padding-bottom: 60px;
}
// body.hidden {
//   overflow: hidden;
// }

// ------------------------------  logo 
.logo{

  font-family: $fflogo;
  font-weight: 400;
  font-size: 20px;
  text-transform: uppercase;
  text-align: center;
  transition: $transition;
  max-width: max-content;
  color: $white;
  &:hover, &:focus {
      color: $accent;
    }
  
  &:active {
    color: second;
  }

  @include tablet{
    font-size: 30px;
  }

}

.burger .logo{
  color: $black;
}

.burger .link{
  font-size: 24px;
}

// ------------------------------ links 

.app-link{
  border-radius: 8px;
    padding: 16px 24px;
    box-shadow: $btn-shadow;
    background: #fdf9db;
    font-weight: 500;
    font-size: 20px;
    text-decoration: underline;
    text-decoration-skip-ink: none;
    text-align: center;
    width: 100%;
    max-width: max-content;
    transition: $transition;
  &:hover,&:focus{
    box-shadow: none;
  }
  &:active{
    box-shadow: $btn-shadow;
  }

}

// ------------------------------ headings 1  2  3
h1{

  font-size: 24px;
  text-transform: uppercase;

}
h2{
  font-size: 32px;
  text-transform: uppercase;
  text-align: center;
}

.subtitle{
  font-weight: 700;
}
// ------------------------------ headings 1  2  3


.disc{
  list-style: disc;
  padding-left: 16px;
}



//margin


//  ------------------------------  for form validate

.just-validate-error-field.form-input{
  border:2px solid $error
}

.just-validate-error-label{

  display: none;
}



//  ------------------------------  mobile/tablet/desktop - hidden 

// _______________________________ submit page start
.submit{
  height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    & main{
      align-self: center;
      text-align: center;
    }



}

    // _______________________________ submit page end

// ========================================= common end





