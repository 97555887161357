@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Patua+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap");
.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
}
@media screen and (max-width: 767.98px) {
  .container {
    max-width: 430px;
  }
}
@media screen and (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media screen and (min-width: 1200px) {
  .container {
    padding: 0 100px;
    max-width: 1200px;
  }
}

.is-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 0;
  margin-bottom: 0;
}

img {
  display: block;
  height: auto;
}

a {
  text-decoration: none;
  color: currentColor;
  display: block;
}

ul, ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

.link {
  text-decoration: none;
}

.list {
  text-decoration: none;
  list-style: none;
  margin: 0;
  padding: 0;
}

li:last-child {
  margin-bottom: 0 !important;
}

svg {
  fill: currentColor;
}

button {
  cursor: pointer;
}

address {
  font-style: normal;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

html,
body {
  font-family: "Inter", sans-serif;
  background-color: #AEDCED;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.2;
  color: #000000;
  margin: 0 auto;
  width: 100%;
  scroll-behavior: smooth;
  overflow-x: hidden;
}
@media screen and (min-width: 1200px) {
  html,
  body {
    font-size: 22px;
  }
}

section {
  padding-top: 60px;
  padding-bottom: 60px;
}

.logo {
  font-family: "Patua One", serif;
  font-weight: 400;
  font-size: 20px;
  text-transform: uppercase;
  text-align: center;
  transition: opacity 0.3s ease-in-out, color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, border-bottom 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  max-width: max-content;
  color: #FFFFFF;
}
.logo:hover, .logo:focus {
  color: #F9C9FD;
}
.logo:active {
  color: second;
}
@media screen and (min-width: 768px) {
  .logo {
    font-size: 30px;
  }
}

.burger .logo {
  color: #000000;
}

.burger .link {
  font-size: 24px;
}

.app-link {
  border-radius: 8px;
  padding: 16px 24px;
  box-shadow: 4px 4px 0 0 #000;
  background: #fdf9db;
  font-weight: 500;
  font-size: 20px;
  text-decoration: underline;
  text-decoration-skip-ink: none;
  text-align: center;
  width: 100%;
  max-width: max-content;
  transition: opacity 0.3s ease-in-out, color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, border-bottom 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}
.app-link:hover, .app-link:focus {
  box-shadow: none;
}
.app-link:active {
  box-shadow: 4px 4px 0 0 #000;
}

h1 {
  font-size: 24px;
  text-transform: uppercase;
}

h2 {
  font-size: 32px;
  text-transform: uppercase;
  text-align: center;
}

.subtitle {
  font-weight: 700;
}

.disc {
  list-style: disc;
  padding-left: 16px;
}

.just-validate-error-field.form-input {
  border: 2px solid #ff3636;
}

.just-validate-error-label {
  display: none;
}

.submit {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.submit main {
  align-self: center;
  text-align: center;
}

.header {
  padding-top: 16px;
  padding-bottom: 16px;
  background-color: #6C98F2;
}
@media screen and (min-width: 1200px) {
  .header {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
@media screen and (min-width: 1200px) {
  .header .container {
    padding-left: 40px;
    padding-right: 40px;
  }
}
.header .navigation {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.header .burger-btn {
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: none;
}
@media screen and (min-width: 1200px) {
  .header .burger-btn {
    display: none;
  }
}
.header-list {
  display: none;
}
@media screen and (min-width: 1200px) {
  .header-list {
    display: flex;
    gap: 8px;
  }
}
.header-list__item a {
  background-color: #fff;
  border-radius: 8px;
  padding: 16px 22px;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.2;
  color: inherit;
  transition: opacity 0.3s ease-in-out, color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, border-bottom 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}
.header-list__item a:hover, .header-list__item a:focus {
  color: #bc00cd;
}

.footer {
  padding-top: 30px;
  padding-bottom: 30px;
  background: #2f2f2f;
  color: #FFFFFF;
}
.footer .navigation {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  font-weight: 500;
  font-size: 14px;
}
@media screen and (min-width: 768px) {
  .footer .navigation {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
}
.footer .list {
  text-align: center;
}
@media screen and (min-width: 1200px) {
  .footer .list {
    text-align: left;
  }
}
.footer .list-mail {
  font-weight: 400;
  font-size: 12px;
}
.footer .link {
  margin-bottom: 16px;
  transition: opacity 0.3s ease-in-out, color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, border-bottom 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}
.footer .link:hover, .footer .link:focus {
  color: #F9C9FD;
}

/* backdrop при відкривтому модальному вікні */
/*  backdrop що огортає враппер модалки */
/*  показати backdrop що огортає враппер модалки */
/* @media all and (max-width:767px){
    .hystmodal__close{
        top:10px;
        right: 10px;
        width: 24px;
        height: 24px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23fff' stroke='%23111' stroke-linecap='square' stroke-miterlimit='50' stroke-width='2' d='M22 2L2 22'/%3E%3Cpath fill='none' stroke='%23111' stroke-linecap='square' stroke-miterlimit='50' stroke-width='2' d='M2 2l20 20'/%3E%3C/svg%3E");
    }
    .hystmodal__window{
        margin: 0;
    }
} */
/* fix html, body */
.hystmodal__opened {
  position: fixed;
  right: 0;
  left: 0;
  overflow: hidden;
}

/* modal backdrop */
.hystmodal__shadow {
  position: fixed;
  border: none;
  display: block;
  width: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: hidden;
  pointer-events: none;
  z-index: 98;
  opacity: 0;
  transition: opacity 0.15s ease;
  background-color: black;
}

/* backdrop при відкривтому модальному вікні */
.hystmodal__shadow--show {
  pointer-events: auto;
  opacity: 0.6;
}

/*  backdrop що огортає враппер модалки */
.hystmodal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  opacity: 1;
  pointer-events: none;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  z-index: 99;
  visibility: hidden;
}

/*  показати backdrop що огортає враппер модалки */
.hystmodal--active {
  pointer-events: auto;
  opacity: 1;
}
.hystmodal--active .hystmodal__window {
  opacity: 1;
}

.hystmodal--moved {
  pointer-events: auto;
  opacity: 1;
}

.hystmodal__wrap {
  flex-shrink: 0;
  flex-grow: 0;
  width: 100%;
  min-height: 100%;
  margin: auto;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
}

.hystmodal__window {
  position: relative;
  margin: 50px 0;
  box-sizing: border-box;
  flex-shrink: 0;
  flex-grow: 0;
  background: #fff;
  width: 600px;
  max-width: 100%;
  overflow: visible;
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
}

.hystmodal__close {
  position: absolute;
  z-index: 10;
  top: 20px;
  right: 20px;
  display: block;
  width: 30px;
  height: 30px;
  background-color: transparent;
  border: none;
  font-size: 0;
  cursor: pointer;
  outline: none;
  transition: color 300ms ease-in-out;
}
.hystmodal__close svg {
  fill: currentColor;
}
.hystmodal__close:hover {
  color: #F9C9FD;
}
.hystmodal__close:focus {
  color: #F9C9FD;
  outline: 2px dotted #afb3b9;
  outline-offset: 2px;
}
.hystmodal__close:active {
  color: inherit;
}

/* ------------ pop-up */
/* ------------ burger */
.hystmodal.hystmodal--burger .hystmodal__wrap {
  justify-content: flex-start;
}
.hystmodal.hystmodal--burger .hystmodal__window {
  margin: 0;
  width: 100%;
  height: 100%;
  color: #000000;
  background-color: #F9C9FD;
  padding-top: 33px;
  padding-bottom: 30px;
}
.hystmodal.hystmodal--burger .hystmodal__window .container {
  height: 100%;
}
.hystmodal.hystmodal--burger .hystmodal__close--btn {
  top: 33px;
  right: 33px;
  color: #000000;
}
.hystmodal.hystmodal--burger .burger-list {
  padding-top: 50px;
}
.hystmodal.hystmodal--burger .burger-list li {
  margin-bottom: 10px;
}

.hero {
  padding-top: 30px;
  padding-bottom: 40px;
}
@media screen and (min-width: 1200px) {
  .hero {
    padding-top: 75px;
    padding-bottom: 150px;
  }
}
.hero .content-wrapper {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
}
@media screen and (min-width: 1200px) {
  .hero .content-wrapper {
    flex-direction: row;
  }
}
.hero p {
  margin-top: 24px;
  margin-bottom: 24px;
}
.hero img {
  box-sizing: 4px 4px 0 0 rgba(255, 253, 253, 0.4);
}

.about {
  background-color: #F9C9FD;
  text-align: center;
}

.gallery {
  background-color: #F9C9FD;
  text-align: center;
}
.gallery .list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}
@media screen and (min-width: 1200px) {
  .gallery .list {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
  }
}
.gallery .list .image {
  width: 100%;
  max-width: 600px;
  box-shadow: 4px 4px 0 0 rgba(255, 253, 253, 0.4);
}
@media screen and (min-width: 1200px) {
  .gallery .list .image {
    max-width: 484px;
  }
}

.contacts {
  padding-top: 100px;
  padding-bottom: 100px;
}

.address {
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: center;
}
.address .subtitle {
  margin-top: 24px;
  padding: 40px 12px;
  text-shadow: 4px 4px 4px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 4px 4px 4px 0 rgba(0, 0, 0, 0.25);
  background: #fff;
  border-radius: 12px;
}

.privacy {
  background-color: #FFFFFF;
}
.privacy h2 {
  font-weight: 800;
  font-size: 40px;
  text-transform: uppercase;
  text-align: center;
}

.form {
  font-family: "Nunito Sans", sans-serif;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 40px;
  width: 100%;
  max-width: 600px;
  background-color: #6C98F2;
  border-radius: 4px;
  margin: 0 auto;
}
.form-label {
  width: 100%;
}
.form-input {
  font-family: inherit;
  width: 100%;
  padding: 8px 16px;
  background: #fff;
  border: 2px solid transparent;
  font-size: 14px;
  line-height: 1.2;
  font-weight: 400;
  border-radius: 6px;
  color: #293037;
  transition: opacity 0.3s ease-in-out, color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, border-bottom 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  outline: none;
}
.form-input:hover, .form-input:focus {
  border: 2px solid #F9C9FD;
}
.form-input::placeholder {
  color: #293037;
  opacity: 0.6;
  font-size: 14px;
  line-height: 1.14;
  font-weight: 400;
}
.form-input.textarea {
  resize: none;
  min-height: 120px;
  border-radius: 8px;
}
.form .app-link {
  font-family: inherit;
  max-width: 100%;
  text-decoration: none;
}

.privacy {
  padding-top: 60px;
  padding-bottom: 60px;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
}
.privacy .title {
  font-weight: 800;
  font-size: 40px;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 40px;
}
@media screen and (min-width: 1200px) {
  .privacy .title {
    font-size: 40px;
  }
}
.privacy h2.title {
  color: #F9C9FD;
}
.privacy .wrapper {
  max-width: 1040px;
  margin: 0 auto;
}
.privacy .upper {
  display: flex;
  flex-direction: column;
  gap: 40px;
  max-width: 1000px;
  margin: 0 auto;
  margin-bottom: 40px;
}
@media screen and (min-width: 768px) {
  .privacy .upper {
    flex-direction: row;
  }
}
.privacy .upper p {
  max-width: 592px;
}
.privacy .lower {
  border-radius: 12px;
  padding: 60px 24px;
  max-width: 1000px;
  margin: 0 auto;
  background-color: #fff5c7;
}
.privacy .lower .mb24 {
  margin-bottom: 24px;
}
.privacy .lower-title {
  font-weight: 600;
  margin-bottom: 24px;
}
.privacy .lower-text {
  margin-top: 24px;
}
.privacy .font600 {
  font-weight: 600;
}