.header{
    padding-top: 16px;
    padding-bottom: 16px;
    background-color: $second-bg;
    @include desktop{
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .container{
        @include desktop{
            padding-left: 40px;
            padding-right: 40px;
        }
    }

    .navigation{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        
    }

    .burger-btn{
        padding: 0;
        margin: 0;
        background-color: transparent;
        border: none;
        @include desktop{
            display: none;
        }
    }

    &-list{
        display: none;
        @include desktop{
            display:  flex;
            gap: 8px;
        }

        &__item{
            a{
                background-color: #fff;
                border-radius: 8px;
                padding: 16px 22px;
                font-weight: 500;
                font-size: 14px;
                line-height: 1.2;
                color:inherit;
                transition: $transition ;
                &:hover,&:focus{
                    color: $second;
                }
            }

        }
    }

}


