.footer{
   padding-top: 30px;
   padding-bottom: 30px;

   background: #2f2f2f;
   color: $white;


   .navigation{
      display: flex;
      flex-direction: column-reverse;
      justify-content: space-between;
      align-items: center;
      gap: 30px;

      font-weight: 500;
         font-size: 14px;
      @include tablet{
         flex-direction: row;
         justify-content: space-between;
         align-items: flex-start;
      }

   }

   .list{
      text-align: center;
      @include desktop{
         text-align: left;
      }
   }
   
   .list-mail{
      font-weight: 400;
         font-size: 12px;
   }

   .link{
      margin-bottom: 16px;
      transition:  $transition;
      &:hover,&:focus{
         color: $accent;
      }
   }
 
}
    

